export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docs"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guide"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced options"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process buildings"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process addresses"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrative boundaries"])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project size is suitable for processing."])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The project is very big. It is recommended to select a district or neighborhood. If they are not defined in OSM, create relations of ", _interpolate(_list(0)), " with level 9 or 10."])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source date"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature count"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pools"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building parts"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review street names"])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Check out the highway/place names displayed on the panel following this ", _interpolate(_list(0)), ". The left column shows the name of the highway/place in Cadastre, the right the proposed conversion to be used when reprocessing. Edit those that are not correct or validate (", _interpolate(_list(1)), ") those that are. Leave it blank (", _interpolate(_list(2)), ") to exclude all addresses on that highway. When you're done, continue to the next step."])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocess"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check fixmes"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locked by"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited by"])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " fixme left."]), _normalize([_interpolate(_named("n")), " fixmes left."])])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " highway left."]), _normalize([_interpolate(_named("n")), " highways left."])])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reviewed ", _interpolate(_list(0)), " of ", _interpolate(_list(1)), " streets."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Reviewed ", _interpolate(_list(0)), " of ", _interpolate(_list(1)), " files."])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download ", _interpolate(_named("upload")), " the files displayed in the panel. Edit them with JOSM following this ", _interpolate(_named("link")), ". Save the results and upload the corrected files ", _interpolate(_named("upload")), ". Check the buildings that have \"fixme\" tags and remove them. You don't have to upload the data to OSM, but here."])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! Confirm to continue."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well done! Reprocess to continue."])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " process finished"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task manager"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Create a new proyect in the ", _interpolate(_list(0)), " using the file ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Fill in the necessary fields by copying (", _interpolate(_list(0)), ") from the template displayed on the panel."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["View the ", _interpolate(_list(0)), "."])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["process result"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buildings"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the process results for backup."])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the process (caution, can't be undone)."])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to clipboard"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish (es)"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English (en)"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the project"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changeset comment"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed instructions"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per task instructions"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select only downloaded files"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop them"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop the reviewed files here or"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select them"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select reviewed file"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, no matching option"])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the province…"])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the municipality…"])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the subarea…"])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin boundaries"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Select the province and municipality to process. If there exists lower ", _interpolate(_list(0)), " (admin_level = 9 - Distrito, admin_level =10 – Barrio), you can also select one."])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process is locked by"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete filter"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name in Cadastre"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " joined ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " leave ", _interpolate(_named("room"))])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review done"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending to review addresses"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending to review fixmes"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process finished"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not processed"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished with error"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denied authorization"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a valid gzip file"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only existing task files"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not delete"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process succesfully deleted"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Process not found"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " started processing of ", _interpolate(_named("room"))])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " restarted processing of ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " deletes processing of ", _interpolate(_named("room"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View in OpenStreetMap"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of building parts to agregate parcels for tasks."])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance in meters to agregate parcels for tasks."])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The value must be between ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), "."])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add project"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share project"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore"])},
  "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parts"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["availlable"])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["running"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["done"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixmes"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
}