export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Català"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentació"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancar sesió"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar-se"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesió"])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instruccions"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcions"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcions avançades"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar edificis"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processar adreces"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estat"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límits administratius"])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mida del projecte és adequada per processar."])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El projecte és molt gran. Es recomana seleccionar un districte o un barri. Si no estan definits a OSM, creeu relacions de ", _interpolate(_list(0)), " amb nivell 9 o 10."])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de publicació"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de característiques"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscines"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parts d'edificis"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisions completades"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar carrerer"])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisa els noms de les vies/llocs mostrats en el panell seguint aquestes ", _interpolate(_list(0)), ". La columna esquerra mostra el nom de la via/lloc a Cadastre, la dreta la conversió proposada que s'usarà en reprocessar. Edita les que no siguin correctes o valida (", _interpolate(_list(1)), ") les que si. Deixa-la en blanc (", _interpolate(_list(2)), ") per a excloure totes les adreces d'aquesta via. Quan acabis continua al següent pas."])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocessar"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir errors"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquejat per"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editat per"])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " fixme."]), _normalize(["Falten ", _interpolate(_named("n")), " fixmes."])])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " carrer."]), _normalize(["Falten ", _interpolate(_named("n")), " carrers."])])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisades ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " carrers."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisats ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " fitxers."])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Baixa't (", _interpolate(_named("download")), ") els arxius mostrats en el panell. Edita'ls amb JOSM seguint aquestes ", _interpolate(_named("link")), ". Guarda els resultats i puja els arxius corregits (", _interpolate(_named("upload")), "). Revisa els edificis que tinguin etiquetes \"fixme\" i elimina-les. No cal pujar les dades a OSM, sinó aquí."])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben fet. Confirma per a continuar."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben fet. Reprocessa per a continuar."])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Procés de ", _interpolate(_list(0)), " finalitzat"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de tasques"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Crea un nou projecte en el ", _interpolate(_list(0)), " usant l'arxiu ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completa els camps necessaris copiant-los (", _interpolate(_list(0)), ") de la plantilla mostrada al panell."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Veure el ", _interpolate(_list(0)), "."])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultat del procés"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["També pots"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificis"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreces"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar"])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixa els resultats del treball com a còpia de seguretat."])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietari/a"])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar el procés (compte, acció no reversible)."])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xat"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar al portapapers"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camp"])},
  "Catalan (ca)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Català (ca)"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espanyol (es)"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglès (en)"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom del projecte"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció breu"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripció"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentari del conjunt de canvis"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruccions detallades"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruccions addicionals per aquesta tasca"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona només els arxius baixats"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixa'ls anar"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrossega els arxius corregits aquí o "])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecciona'ls"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar arxiu corregit"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, opció no trobada"])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la província..."])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el municipi..."])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la divisió..."])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisions administratives"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Selecciona una província i el municipi a processar. Si existeixen ", _interpolate(_list(0)), " del municipi (admin_level = 9 - districtes o admin_level = 10 – barris), també pots seleccionar una."])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El procés està bloquejat per"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar filtre"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom a Cadastre"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversió"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " ha entrat a ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " ha sortit de ", _interpolate(_named("room"))])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processant"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent de revisar adreces"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendent de revisar problemes"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procés finalitzat"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No processat"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha acabat amb error"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorització denegada"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No és un arxiu gzip vàlid"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Només arxius de tasques existents"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es va poder eliminar"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procés eliminat correctament"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procés no trobat"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " inicia el procés de ", _interpolate(_named("room"))])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " reinicia el procés de ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " elimina el procés de ", _interpolate(_named("room"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fet"])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure a OpenStreetMap"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desfer"])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de parts d'edifici per començar a afegir parcel·les per a tasques."])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distància en metres per afegir parcel·les per a tasques."])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El valor ha d'estar entre ", _interpolate(_list(0)), " i ", _interpolate(_list(1)), "."])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear projecte"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir projecte"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
  "Origen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuari"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasques"])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parts"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["executant-se"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acabat"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["carrerer"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corregir"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
}