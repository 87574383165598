export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "Docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
  "Log in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
  "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["instrucciones"])},
  "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar"])},
  "Options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
  "Advanced options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones avanzadas"])},
  "Process buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar edificios"])},
  "Process addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesar direcciones"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
  "admin-boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límites administrativos"])},
  "project-size-ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tamaño del proyecto es adecuado para procesar."])},
  "project-size-ko": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El proyecto es muy grande. Se recomienda seleccionar un distrito o barrio. Si no están definidos en OSM crea relaciones de ", _interpolate(_list(0)), " con nivel 9 o 10."])},
  "Source date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación"])},
  "Feature count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de características"])},
  "Pools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscinas"])},
  "Building parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partes de edificios"])},
  "Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar"])},
  "Review done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisiones completadas"])},
  "Review street names": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisar callejero"])},
  "review_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisa los nombres de las vías/lugares mostrados en el panel siguiendo estas ", _interpolate(_list(0)), ". La columna izquierda muestra el nombre de la vía/lugar en Catastro, la derecha la conversión propuesta que se usará al reprocesar. Edita las que no sean correctas o valida (", _interpolate(_list(1)), ") las que si. Déjala en blanco (", _interpolate(_list(2)), ") para excluir todas las direcciones de esa vía. Cuando termines continúa al siguiente paso."])},
  "Reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprocesar"])},
  "Check fixmes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir errores"])},
  "Locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado por"])},
  "Edited by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editado por"])},
  "fixmes left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " fixme."]), _normalize(["Faltan ", _interpolate(_named("n")), " fixmes."])])},
  "highways left": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Falta ", _interpolate(_named("n")), " vía."]), _normalize(["Faltan ", _interpolate(_named("n")), " vías."])])},
  "reviewed_hgw": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisadas ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " calles."])},
  "reviewed_files": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Revisados ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " archivos."])},
  "fixme_msg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Descarga (", _interpolate(_named("download")), ") los archivos mostrados en el panel. Edítalos con JOSM siguiendo estas ", _interpolate(_named("link")), ". Guarda los resultados y sube los archivos corregidos (", _interpolate(_named("upload")), "). Revisa los edificios que tengan etiquetas \"fixme\" y elimínalas. No hay que subir los datos a OSM, sino aquí."])},
  "welldone1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bien hecho! Confirma para continuar."])},
  "welldone2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Bien hecho! Reprocesa para continuar."])},
  "Confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "finish_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Proceso de ", _interpolate(_list(0)), " finalizado"])},
  "Publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
  "Task manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestor de tareas"])},
  "done_msg1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Crea un nuevo proyecto en el ", _interpolate(_list(0)), " usando el archivo ", _interpolate(_list(1)), "."])},
  "done_msg2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completa los campos necesarios copiándolos (", _interpolate(_list(0)), ") de la plantilla mostrada en el panel."])},
  "done_msg3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ver el ", _interpolate(_list(0)), "."])},
  "process result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultado del proceso"])},
  "You can also": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También puedes"])},
  "Buildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificios"])},
  "Addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones"])},
  "Management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar"])},
  "export_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga los resultados del trabajo como copia de seguridad."])},
  "Export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
  "delete_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar el proceso (cuidado, acción no reversible)."])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charla"])},
  "Copy to clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar al portapapeles"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo"])},
  "Spanish (es)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español (es)"])},
  "English (en)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés (en)"])},
  "Name of the project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del proyecto"])},
  "Short description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción corta"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "Changeset comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario del conjunto de cambios"])},
  "Detailed instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones detalladas"])},
  "Per task instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucciones adicionales para esta tarea"])},
  "Select only downloaded files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione sólo los archivos descargados"])},
  "Drop them": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suéltalos"])},
  "Drop here or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrastra los archivos corregidos aquí o "])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selecciónalos"])},
  "Select reviewed file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo corregido"])},
  "Sorry, no matching option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo siento, opción no encontrada"])},
  "Select the province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la provincia..."])},
  "Select the municipality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el municipio..."])},
  "Select the subarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona la división..."])},
  "admin boundaries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["divisiones administrativas"])},
  "select_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Selecciona una provincia y el municipio a procesar. Si existen ", _interpolate(_list(0)), " del municipio (admin_level = 9 - distritos o admin_level = 10 – barrios), también puedes seleccionar una."])},
  "Log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
  "The process is locked by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El proceso está bloqueado por"])},
  "Report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informe"])},
  "Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
  "Delete filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar filtro"])},
  "Name in Cadastre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre en Catastro"])},
  "Conversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversión"])},
  "joined": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " se unió a ", _interpolate(_named("room"))])},
  "leave": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " abandonó ", _interpolate(_named("room"))])},
  "Procesando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesando"])},
  "Pendiente de revisar direcciones": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de revisar direcciones"])},
  "Pendiente de revisar problemas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de revisar problemas"])},
  "Proceso finalizado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso finalizado"])},
  "No procesado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No procesado"])},
  "Terminó con error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminó con error"])},
  "Autorización denegada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización denegada"])},
  "No es un archivo gzip válido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No es un archivo gzip válido"])},
  "Sólo archivos de tareas existentes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo archivos de tareas existentes"])},
  "No se pudo eliminar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo eliminar"])},
  "Proceso eliminado correctamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso eliminado correctamente"])},
  "Proceso no encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso no encontrado"])},
  "create_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " inicia el proceso de ", _interpolate(_named("room"))])},
  "restart_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " reinicia el proceso de ", _interpolate(_named("room"))])},
  "delete_job": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("username")), " elimina el proceso de ", _interpolate(_named("room"))])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])},
  "view_in_osm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en OpenStreetMap"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "Undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshacer"])},
  "parcel_parts_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de partes de edificio para comenzar a agregar parcelas para tareas."])},
  "parcel_dist_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distancia en metros para agregar parcelas para tareas."])},
  "between_val": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El valor debe estar entre ", _interpolate(_list(0)), " y ", _interpolate(_list(1)), "."])},
  "add_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear proyecto"])},
  "Share project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir proyecto"])},
  "Explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorar"])},
  "Source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origen"])},
  "Project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proyecto"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tareas"])},
  "parts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partes"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
  "RUNNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ejecutándose"])},
  "DONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terminado"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["callejero"])},
  "FIXME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correcciones"])},
  "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error"])}
}